<template>
	<div>
		<el-dialog modal @close="closeDialog" top="30vh" :visible.sync="open" :close-on-press-escape="false"
			:close-on-click-modal="false" center>
			<template>
				<div v-show="showCommonText || isMember" style="font-size: 30px; color: #1181fa; text-align: center;">
					购买服务，立享使用特权</div>
				<div v-show="!showCommonText && !isMember" style="font-size: 30px; color: #1181fa; text-align: center;">{{
					text }}</div>
			</template>
			<span slot="footer" class="dialog-footer">
				<el-button v-show="showPayBtn" type="primary" @click="toPay">支付购买</el-button>
				<el-button v-show="showMemberBtn && isMember" type="primary" @click="toMember">开通会员</el-button>
				<el-button v-show="showMemberBtn && !isMember && memberId != 1" type="primary"
					@click="toMember('upgradation')">会员升级</el-button>
			</span>
		</el-dialog>
		<pay-modal ref="payModal" :conPO="conPO"></pay-modal>
	</div>
</template>
<script>
import PayModal from "@/components/payModal";
export default {
	components: {
		PayModal,
	},
	props: {
		// 是否显示弹窗
		visible: {
			type: Boolean,
			default: false,
		},
		showPayBtn: {
			type: Boolean,
			default: false,
		},
		showMemberBtn: {
			type: Boolean,
			default: true,
		},
		showCommonText: {
			type: Boolean,
			default: true,
		},
		text: {
			type: String,
			default: "",
		},
		conPO: {
			type: Object,
		},
		type: {
			type: String,
			default: "",
		},
	},
	watch: {
		visible(val) {
			if (val) {
				this.stopScroll();
			} else {
				this.canScroll();
			}
		},
	},
	data() {
		return {
			isMember: false,
			orderData: {},
			open: false,
			memberId: null
		};
	},
	mounted() {
		this.isMember = this.$session.getUsers().memberId == 7 ? true : false
		this.memberId = this.$session.getUsers().memberId
	},
	methods: {
		closeDialog() {
			this.open = false;
		},
		openDialog() {
			this.open = true;
			this.$refs.payModal.orderData = this.orderData;
			this.$refs.payModal.getOrderData();
		},
		toMember(isUpgradation) {
			let url = this.$router.resolve({
				path: `/member`,
				query: {
					isUpgradation: isUpgradation
				}
			});
			window.open(url.href, "_blank");
		},
		// 去支付
		toPay() {
			let func;
			if (this.type == '行业动态') {
				func = this.$api.industry.industryDynamicPayOrderDetail(this.orderData)
			}
			if (this.type == '市场简报') {
				func = this.$api.research.getPayDetail({ reportId: this.orderData.reportId, readOrDownLoad: this.orderData.readOrDownLoad , orderType: '630302' })
			}
			if (this.type == '数据超市') {
				func = this.$api.research.getPayDetail({ reportId: this.orderData.reportId, readOrDownLoad: this.orderData.readOrDownLoad, orderType: '630301' })
			}
			if (this.type == '专题报告') {
				func = this.$api.research.getPayDetail({ reportId: this.orderData.reportId, readOrDownLoad: this.orderData.readOrDownLoad, orderType: '630303' })
			}
			func.then(res => {
				this.orderData = res.data;
				this.$refs.payModal.orderData = { ...res.data, reportPay: true,type:this.type };
				this.$refs.payModal.payOrder();
				this.$refs.payModal.open = true;
				this.open = false;
			}).catch(error => {
				this.$message.error(error?.msg);
			});
		},
		//禁止滚动
		stopScroll() {
			var mo = function (e) {
				e.preventDefault();
			};
			document.body.style.overflow = "hidden";
			document.addEventListener("touchmove", mo, false); //禁止页面滑动
		},
		/***允许滚动***/
		canScroll() {
			var mo = function (e) {
				e.preventDefault();
			};
			document.body.style.overflow = ""; //出现滚动条
			document.removeEventListener("touchmove", mo, false);
		},
	},
};
</script>
<style lang="less">
.el-dialog__wrapper {
	.el-dialog {
		// height: 558px !important;
		// margin: 0 !important;s
		border-radius: 20px;
	}
}</style>
